import React, { useContext } from "react";
import {
  QueHeader,
  QueLayout,
  QueRadioOptions,
  SmallRadioBox,
  OurPartners,
} from "../../question_components/container";
import { FAQS } from "../../data/product-page-data/BusinessPhoneData";
import { Que2Data } from "../../question_components/data/PhoneSystem";
import { navigate } from "gatsby";
import PhoneContext from "../../contexts/phoneContext";

export default function Question2() {
  const { que2, setQue2 } = useContext(PhoneContext);

  return (
    <QueLayout FaqData={FAQS}>
      <QueHeader
        title="Let Us Find You The Best Business Phone System"
        progress={40}
        number="Question 2"
        question="How many handsets or user extensions do you need?"
        info="Choose one option from below."
      ></QueHeader>
      {Que2Data.map((item) => {
        return (
          <QueRadioOptions
            normal
            error={que2 === null ? true : false}
            back={item.back}
            next={que2 === null ? null : item.next}
          >
            {item.options.map((items) => {
              return (
                <SmallRadioBox
                  key={items.answer}
                  icon={<items.icon />}
                  value={items.answer}
                  title={items.answer}
                  isSelected={que2 === items.answer}
                  onChange={(e) => {
                    setQue2(e.target.value);
                    navigate(items.to);
                  }}
                />
              );
            })}
          </QueRadioOptions>
        );
      })}
      <OurPartners />
    </QueLayout>
  );
}
